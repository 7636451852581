import { render, staticRenderFns } from "./GenericCrossImages.vue?vue&type=template&id=1bdba337"
import script from "./GenericCrossImages.vue?vue&type=script&lang=js"
export * from "./GenericCrossImages.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UtilHeadingAdvanced: require('/srv/app/components/util/HeadingAdvanced.vue').default,PictureEnlarge: require('/srv/app/components/picture/PictureEnlarge.vue').default,Markdown: require('/srv/app/components/markdown/Markdown.vue').default})
