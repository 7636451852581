
export default {
  name: 'CardCampaign',
  props: {
    cardCampaign: {
      type: Object,
      default: () => {},
    },
  }
}
