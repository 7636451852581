
import CloseForm from '@/mixins/close-form.js'

export default {
  mixins: [CloseForm],
  props: {
    heroDetail: {
      type: Object,
      default: () => {},
    },
    headingNumber: {
      type: Number,
      default: 2,
    }
  },
  // data() {
  //   return {
  //     documentTitle: '',
  //   }
  // },
  // computed: {
  //   campaignQueries() {
  //     const queries = { ...this.$route.query }
  //     const queryString = Object.keys(queries)
  //       .map(
  //         (key) =>
  //           `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`
  //       )
  //       .join('&')
  //     return queryString
  //   },
  // },
  // beforeMount() {
  //   window.addEventListener('scroll', this.handleScroll)
  //   // this.documentTitle = document.title
  // },
  // beforeDestroy() {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },
  methods: {
    handleScroll() {
      // if (
      //   this.$refs.leadForm &&
      //   this.closedForm !== 'true' &&
      //   window.scrollY > 900
      // )
      // this.$refs.leadForm.showModal = true
    },
  },
}
