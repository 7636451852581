
import SEO from '@/mixins/SEO.js'
import Banners from '@/mixins/banners.js'
import globalEventListeners from '@/mixins/globalEventListeners';

export default {
  mixins: [SEO, Banners,globalEventListeners],
  layout: 'default',
  data() {
    return {
      seo: {
        title: '',
        description: '',
        keywords: '',
        shareImage: '',
        canonical: '',
        BirincilKampanya: '',
        preventIndexing: '',
        sitemap: '',

      },
      page: {},
      hero: {},
      sectionSolutions: [],
      components: [],
      navigationScrollItems: [],
      navigationScrollTitleImage: null,
      navigationScrollTitle: '',
      sectionCardsAction: {
        actionCard: [],
      },
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(
      `/solutions?slug=${this.$route.path.replace(/^\/|\/$/g, '')}`
    )
    this.page = data[0]
    // Breadcrumb

    this.breadcrumb = this.page.slug

    this.solutions = data
    const page = this.page
    // SEO
    this.seo = page.SEO ? page.SEO : page
    // Page Modules Parameter
    const details = page.detail
    this.components = details.filter((item) => item.__component)
    // MODULES //
    // Hero
    this.hero = page.hero
    // Scroll Navigation
    this.navigationScrollItems = details.filter(
      (item) => item.__component !== 'site.banner'
    )
    if (page.productLogo) {
      this.navigationScrollTitleImage = page.productLogo
    } else {
      this.navigationScrollTitleImage = null
      this.navigationScrollTitle = page.title
    }
    this.sectionSolutions = page.cardSolutions
    this.sectionCardsAction =
      this.page.cardAction !== null
        ? this.page.cardAction
        : this.sectionCardsAction
  },
}
