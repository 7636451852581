
export default {
  name: 'SectionsAccordion',
  props: {
    sectionAccordion: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      accordionIndex: null,
      displayingFaqCount: 5,
    }
  },
  computed: {
    jsonLD() {
      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": this.sectionAccordion.faqList.map(faq => ({
          "@type": "Question",
          "name": faq.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.answer
          }
        }))
      };
    }
  },
  head() {
    let headObject = {
      script: []
    };

    if (this.jsonLD && this.jsonLD.mainEntity && this.jsonLD.mainEntity.length > 0) {
      headObject.script.push({
        type: 'application/ld+json',
        json: this.jsonLD
      });
    }

    return headObject;
    /*
    return {
      script: [
        {
          type: 'application/ld+json',
          json: this.jsonLD
        }
      ]
    };
    //*/
  },
  methods: {
    accordion(index) {
      this.accordionIndex === index
        ? (this.accordionIndex = null)
        : (this.accordionIndex = index)
    },
    showMoreFaq() {
      this.displayingFaqCount += 5
    },
  },
}
