
import UTILS from '@/mixins/utils'
export default {
  name: 'SectionsGeneric',
  mixins: [UTILS],
  props: {
    sectionGeneric: {
      type: Object,
      default: () => {},
    },
  },
}
