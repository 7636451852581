
export default {
  name: 'SectionsOverviewThree',
  props: {
    sectionOverviewThree: {
      type: Object,
      default: () => {},
    },
  },
}
