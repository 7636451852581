
export default {
  name: 'SectionsSolutions',
  props: {
    sectionSolutions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  methods: {
    tab(index) {
      this.tabIndex = index
    },
  },
}
