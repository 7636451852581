import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=4d96a463"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationScroll: require('/srv/app/components/navigation/Scroll.vue').default,HeroDetail: require('/srv/app/components/hero/HeroDetail.vue').default,SectionsSolutions: require('/srv/app/components/sections/Solutions.vue').default,SectionsOverviewThree: require('/srv/app/components/sections/OverviewThree.vue').default,SectionsOverviewTwo: require('/srv/app/components/sections/OverviewTwo.vue').default,SectionsGenericCrossImagesOrTab: require('/srv/app/components/sections/GenericCrossImagesOrTab.vue').default,SectionsGenericCrossImages: require('/srv/app/components/sections/GenericCrossImages.vue').default,SectionsGeneric: require('/srv/app/components/sections/Generic.vue').default,SectionsCardsAction: require('/srv/app/components/sections/CardsAction.vue').default,SectionsAccordion: require('/srv/app/components/sections/Accordion.vue').default,SectionsTestimonials: require('/srv/app/components/sections/Testimonials.vue').default,CardCampaign: require('/srv/app/components/card/CardCampaign.vue').default,SectionsRelatedPosts: require('/srv/app/components/sections/RelatedPosts.vue').default,CardBlog: require('/srv/app/components/card/CardBlog.vue').default,BannerProductFinder: require('/srv/app/components/banner/ProductFinder.vue').default,BannerProductCompare: require('/srv/app/components/banner/ProductCompare.vue').default,BannerCallUs: require('/srv/app/components/banner/CallUs.vue').default,BannerBusinessPartner: require('/srv/app/components/banner/BusinessPartner.vue').default,Breadcrumb: require('/srv/app/components/breadcrumb/Breadcrumb.vue').default})
