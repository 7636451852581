import { render, staticRenderFns } from "./Generic.vue?vue&type=template&id=df2c2fb2"
import script from "./Generic.vue?vue&type=script&lang=js"
export * from "./Generic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UtilHeadingAdvanced: require('/srv/app/components/util/HeadingAdvanced.vue').default,Picture: require('/srv/app/components/picture/Picture.vue').default,Markdown: require('/srv/app/components/markdown/Markdown.vue').default,Button: require('/srv/app/components/button/Button.vue').default})
