
import UTILS from '@/mixins/utils'
export default {
  name: 'SectionsGenericCrossImagesOrTab',
  mixins: [UTILS],
  props: {
    sectionGenericCrossImagesOrTab: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: 0,
    }
  },
  methods: {
    activeItem(index) {
      this.show = index
    },
  },
}
