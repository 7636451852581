
import UTILS from '@/mixins/utils'
export default {
  name: 'SectionsGenericCrossImages',
  mixins: [UTILS],
  props: {
    sectionGenericCrossImages: {
      type: Object,
      default: () => {},
    },
  },
}
