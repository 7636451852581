
// Animation


export default {
  name: 'ScrollMenu',
  props: {
    navigationScrollTitleImage: {
      type: Object,
      default() {},
    },
    navigationScrollTitle: {
      type: String,
      default: '',
    },
    menu: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  async mounted() {
    const x = await this.$refs.navigationSticky
    await this.menu[0]
    if (x) {
      this.selected = this.menu[0] ? this.menu[0].sectionTitle : ''
      await this.NavigationScroll()
    }
  },
  methods: {
    selectItem(item) {
      this.selected = item.sectionTitle
    },

    NavigationScroll() {
    const navigation = document.querySelector('.navigation-scroll-sticky')
    const panels = Array.from(document.querySelectorAll('[data-id]'))
    const navLinks = Array.from(document.querySelectorAll('.navigation-link'))

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        navigation.classList.add('header-fixed')
      } else {
        navigation.classList.remove('header-fixed')
      }

      panels.forEach((panel, i) => {
        const rect = panel.getBoundingClientRect()
        if (rect.top <= 160 && rect.bottom > 160) {
          navLinks.forEach((e) => {
            e.classList.remove('active')
          })
          navLinks[i].classList.add('active')
          this.selected = navLinks[i].text
        }
      })
    })
  },


  },
}
