
export default {
  name: 'SectionsRelatedPosts',
  props: {
    sectionRelatedPosts: {
      type: Object,
      default() {},
    },
  },
}
