
export default {
  name: 'CardInfoTags',
  props: {
    cardInfoTags: {
      type: Object,
      default: () => {},
    },
  },
}
