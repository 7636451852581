
export default {
  name: 'CardNewsroom',
  props: {
    cardNewsroom: {
      type: Object,
      default: () => {},
    },
  }
}
