export default {
  methods: {
    imageExtCheckWidth(ext) {
      const validExtensions = ['.jpeg', '.jpg', '.webp'];

      return {
        'w-100': ext && validExtensions.includes(ext)
      };
    },
    imageExtCheckVisibility(ext) {
      const validExtensions = ['.jpeg', '.jpg', '.webp'];

      return ext && validExtensions.includes(ext) ? 'd-none' : 'd-lg-block'
    }
  }
};
