
export default {
  name: 'CardBlog',
  props: {
    cardBlog: {
      type: Object,
      default: () => {},
    },
  },
}
