import { render, staticRenderFns } from "./GenericCrossImagesOrTab.vue?vue&type=template&id=29a81ed9"
import script from "./GenericCrossImagesOrTab.vue?vue&type=script&lang=js"
export * from "./GenericCrossImagesOrTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UtilHeadingAdvanced: require('/srv/app/components/util/HeadingAdvanced.vue').default,PictureEnlarge: require('/srv/app/components/picture/PictureEnlarge.vue').default,Picture: require('/srv/app/components/picture/Picture.vue').default,Markdown: require('/srv/app/components/markdown/Markdown.vue').default,TabVertical: require('/srv/app/components/tab/TabVertical.vue').default})
