
export default {
  data() {
    return {
      closedForm: 'false'
    }
  },
  mounted(){
    this.closedForm = sessionStorage.getItem('MYclosedForm')
  },
  methods: {
    closedFormModal() {
      sessionStorage.setItem('MYclosedForm', 'true')
      this.closedForm = sessionStorage.getItem('MYclosedForm')
    },
  }
}
