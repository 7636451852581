
export default {
  name: 'CardIcon',
  props: {
    cardIcon: {
      type: Object,
      default: () => {},
    },
  },
}
