
const props = {
  text: {
    type: String,
    default: '',
  },
}
const WORDS_PER_MINUTE = 250 // Average person wpm

const computed = {
  time() {
    const words = this.text.split(' ').length
    return Math.ceil(words / WORDS_PER_MINUTE)
  },
}
const ReadingTime = {
  name: 'readingTime',
  props,
  computed,
}
export default ReadingTime
